import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectMrondaPunto (Vue, filter, search, sorter, page, idmronda) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addExact('idmronda', idmronda)
    if (search) {
      const apiFilterSearch = new APIFilter()
      apiFilterSearch
        .setOperator(API_FILTER_OPERATORS.OR)
        .addILike('codigo', search)
        .addILike('descripcion', search)
      apiFilter.addNestedFilter(apiFilterSearch)
    }
    if (filter.codigo.value) {
      apiFilter.addILike(filter.codigo.field, filter.codigo.value)
    }
    if (filter.descripcion.value) {
      apiFilter.addILike(filter.descripcion.field, filter.descripcion.value)
    }
    const resp = await Vue.$api.call('mrondaPunto.select', {
      page,
      filter: apiFilter,
      sorter,
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectMrondaPuntoRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addIn('idmronda_punto', pks)
    const resp = await Vue.$api.call('mrondaPunto.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  }
}
